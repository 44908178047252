<template>
  <b-modal
    id="calendar-schedule-cancel-modal"
    title="Tùy chọn hủy lịch"
    centered
    hide-footer
  >
    <form @submit.prevent="handleValidate">
      <b-row>
        <b-col class="col-option">
          <basic-radio
            v-validate="{
              required: true,
            }"
            v-model="option"
            :options="options"
            :state="validateState('option')"
            :invalidFeedback="errors.first('option')"
            required
            name="option"
            data-vv-as="Tùy chọn"
          />
        </b-col>
      </b-row>

      <!-- Actions -->
      <div class="d-flex justify-content-end align-items-center">
        <b-button class="btn mr-2" type="button" @click="onClickCancelButton">
          Hủy
        </b-button>
        <b-button class="btn btn-success ml-3" type="submit">
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
          </span>
          Lưu
        </b-button>
      </div>
    </form>
  </b-modal>
</template>

<script>
import { CALENDAR_CANCEL } from '@/core/plugins/constants.js';

export default {
  name: 'CalendarScheduleCancel',

  data() {
    return {
      option: 0,

      options: [
        { name: 'Chỉ lịch này', value: CALENDAR_CANCEL.CURRENT },
        {
          name: 'Lịch này và lịch tiếp theo',
          value: CALENDAR_CANCEL.CURRENT_AND_NEXT,
        },
        { name: 'Tất cả các lịch', value: CALENDAR_CANCEL.ALL },
      ],
    };
  },

  methods: {
    handleValidate() {
      this.$validator.validateAll().then((result) => {
        if (result) this.handleSubmit();
      });
    },

    handleSubmit() {
      this.$bvModal.hide('calendar-schedule-cancel-modal');
      this.onShowConfirmModal();
    },

    onShowConfirmModal() {
      this.$swal({
        title: 'Xác nhận',
        text: `Bạn có chắc chắn muốn hủy lịch hẹn này không`,
        icon: 'warning',
        buttons: {
          cancel: {
            text: 'Quay lại',
            value: false,
            visible: true,
            className: '',
            closeModal: true,
          },
          confirm: {
            text: 'Hủy hẹn',
            value: true,
            visible: true,
            className: '',
            closeModal: true,
          },
        },
      }).then(
        function (result) {
          if (result) {
            this.$emit('save', this.option);
          }
        }.bind(this),
      );
    },

    onClickCancelButton() {
      this.$bvModal.hide('calendar-schedule-cancel-modal');
    },

    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.errors.has(ref);
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
.calendar-schedule-cancel-modal__dropdown {
  width: 96px;
}

.calendar-schedule-cancel-modal__chip-group {
  .v-chip {
    border-radius: 50%;
    height: 40px;
    font-size: 13px;
  }

  .v-chip--active {
    background-color: #b1dddb;
    color: #008479;
  }
}

.col-option {
  padding-bottom: 20px;
}

.col-date {
  display: flex;
  align-items: flex-end;
}
</style>
